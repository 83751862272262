<template>
  <div style="width: 100%;" @click="handleClear">
    <div style="width: 100%" v-loading="isLoading">
      <el-main style="margin-top: 136px;">
        <el-form class="form-container">
          <PaginationToolbar
            :sort-by-options="sortByOptions"
            :content-api="contentAPI"
            @success="$emit('replaceContent', $event)"
          >
          </PaginationToolbar>
        </el-form>
        <el-scrollbar :native="true">
          <div class="contentListContainer">
            <el-table
              ref="callLogTable"
              class="list-table"
              :data="queueCallLog"
              highlight-current-row
              @row-click="handleSingleClick"
            >
              <el-table-column
                :label="__('Waiter UUID')"
                prop="waiter_uuid"
                min-width="160"
              >
                <template slot-scope="scope">
                  <el-row
                    type="flex"
                    justify="flex-start"
                    style="align-items: center"
                  >
                    <img
                      :src="getRowIcon(scope.row)"
                      class="rounded-menu-icon"
                    />
                    <span style="margin-left: 20px;">{{
                      scope.row.waiter_uuid
                    }}</span>
                  </el-row>
                </template>
              </el-table-column>
              <el-table-column
                :label="__('Queue Name')"
                prop="qfm_queue.data[0].queue_name"
                min-width="120"
              />
              <el-table-column
                :label="__('Call Center Number')"
                prop="queue_phone_number"
                min-width="150"
              />
              <el-table-column
                :label="__('Transfer Number')"
                prop="transfer_phone_number"
                min-width="130"
              />
              <el-table-column :label="__('Call Outcome')" min-width="130">
                <template slot-scope="scope">
                  <div style="display: flex; align-items: center">
                    <div :style="getStatusStyles(scope.row.call_outcome)"></div>
                    {{ capitalize(__(scope.row.call_outcome)) }}
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-scrollbar>
      </el-main>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PaginationToolbar from "@/components/PaginationToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";

export default {
  name: "QForMeAnswerLogList",
  mixins: [BaseContent],
  components: {
    PaginationToolbar
  },
  props: {
    msg: {
      required: false,
      type: String,
      default: ""
    }
  },
  data() {
    return {
      sortByColumns: ["waiter_uuid", "call_log_id"],
      fetchedAccounts: false,
      search: "",
      filters: ["queue_name"],
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10
      }
    };
  },

  computed: {
    ...mapState("qforme", {
      queueCallLog: state => state.queueCallLog,
      isLoading: state => state.loading
    }),

    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId
    })
  },

  mounted() {
    if (this.msg) {
      this.$message({
        type: "success",
        message: this.msg
      });
    }
  },
  methods: {
    ...mapActions("qforme", {
      contentAPI: "getQformeCallLog"
    }),

    capitalize(label) {
      label = label.replace("_", " ");
      return label.charAt(0).toUpperCase() + label.slice(1);
    },

    getStatusStyles(status) {
      let statusStyles = {
        width: "12px",
        height: "12px",
        "border-radius": "8px",
        "margin-right": "10px",
        border: "3px solid"
      };

      const statusColorMap = {
        connected: "#51B848",
        transferred: "#1CD1A1",
        "not answered": "#BE1E2A",
        failed: "#F84856"
      };

      statusStyles["border-color"] = statusColorMap[status] || "#1CD1A1";
      return statusStyles;
    },

    isPremium(rowItem) {
      if (
        rowItem.ac_plan.data.length > 0 &&
        rowItem.ac_plan.data[0].plan_type === "QforMe Premium"
      ) {
        return true;
      }
    },

    getRowIcon(rowItem) {
      if (this.isPremium(rowItem)) {
        return require("@/assets/icons/icon-premium-waiter.svg");
      } else {
        return require("@/assets/icons/icon-waiter.svg");
      }
    },

    handleSingleClick(row, column, event) {
      event.stopPropagation();
      this.handleSelect(null, row);
    },

    handleClear() {
      this.$refs.callLogTable && this.$refs.callLogTable.setCurrentRow();
      this.handleClearSelection();
    }
  },
  watch: {
    selectedItemIndex: {
      immediate: true,
      handler: function(val) {
        if (val !== -1 && val !== null && val !== undefined) {
          this.$refs.callLogTable &&
            this.$refs.callLogTable.setCurrentRow(this.waiters[val]);
        } else if (val === -1) {
          this.$refs.callLogTable && this.$refs.callLogTable.setCurrentRow();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-list.scss";
@import "~@/styles/pagination-toolbar.scss";
</style>
