<script>
import List from "./pages/list";
import CreateOrEdit from "./pages/createOrEdit";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";
import { mapState } from "vuex";

export default {
  name: "QForMeAnswerLogIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("qforme", {
      contents: state => state.queueCallLog
    })
  },

  methods: {
    initContent() {
      return {
        waiter_id: "",
        queue_phone_number: "",
        transfer_phone_number: "",
        waiter_uuid: "",
        answered_at: "",
        waiting_duration: "",
        call_outcome: "",
        queue_id: "",
        ac_plan_id: ""
      };
    }
  },

  mounted() {
    this.contentType = "QforMe Call Log";
    this.primaryKey = "call_log_id";
    this.scope = "ac";
  }
};
</script>

<style lang="scss" scoped></style>
