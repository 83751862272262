<template>
  <div v-if="formAction" style="padding: 20px">
    <div>
      <div class="titles">{{ __("Queue Name") }}</div>
      <div class="details">
        {{ contentFormInfo.qfm_queue.data[0].queue_name }}
      </div>
      <div class="titles">{{ __("Account Plan") }}</div>
      <div class="details">
        {{ contentFormInfo.ac_plan.data[0].plan_type }}
      </div>
      <div class="titles">{{ __("Queue Phone Number") }}</div>
      <div class="details">{{ contentFormInfo.queue_phone_number }}</div>
      <div class="titles">{{ __("Transfer Phone Number") }}</div>
      <div class="details">{{ contentFormInfo.transfer_phone_number }}</div>
      <div class="titles">{{ __("Call Outcome") }}</div>
      <div class="details">{{ contentFormInfo.call_outcome }}</div>
      <div v-show="isPremium(contentFormInfo)">
        <div class="titles">{{ __("Caller Intro TTS") }}</div>
        <div class="details">
          <audio-player
            class="audio-player"
            :simplePlayer="true"
            :show-duration="true"
            :show-seek-bar="true"
            :show-download="true"
            icon-play="/icons/play1.svg"
            :file="getTTSAudio(contentFormInfo)"
          />
        </div>
        <div class="titles">{{ __("Caller Message") }}</div>
        <div class="details">
          <audio-player
            class="audio-player"
            :simplePlayer="true"
            :show-duration="true"
            :show-seek-bar="true"
            :show-download="true"
            icon-play="/icons/play1.svg"
            :file="getAudio(contentFormInfo)"
          />
        </div>
      </div>
      <div v-if="contentFormInfo.call_channel_id">
        <div class="titles">
          {{ __("Call Channel ID") }}
        </div>
        <div class="details">
          {{ contentFormInfo.call_channel_id }}
        </div>
      </div>
      <div v-if="contentFormInfo.outbound_call_uuid">
        <div class="titles">
          {{ __("Outbound Call UUID") }}
        </div>
        <div class="details">
          {{ contentFormInfo.outbound_call_uuid }}
        </div>
      </div>
      <div class="titles">
        {{ __("Created At") }}
      </div>
      <div class="details">
        {{ convertCreatedAt(contentFormInfo.created_at) }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import AudioPlayer from "@/components/AudioPlayer";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    AudioPlayer
  },

  computed: {},
  methods: {
    convertCreatedAt(waiterTime) {
      if (waiterTime) {
        return moment(waiterTime).format("MMM D, y hh:mm A");
      }
    },

    isPremium(rowItem) {
      if (rowItem.ac_plan.data[0].plan_type === "QforMe Premium") {
        return true;
      }
    },

    getTTSAudio(rowItem) {
      if (rowItem && rowItem.qfm_waiter.data[0].caller_info) {
        let caller_info = JSON.parse(rowItem.qfm_waiter.data[0].caller_info);
        return caller_info["caller_text"];
      } else {
        return "";
      }
    },

    getAudio(rowItem) {
      if (rowItem && rowItem.qfm_waiter.data[0].caller_info) {
        let caller_info = JSON.parse(rowItem.qfm_waiter.data[0].caller_info);
        return caller_info["caller_audio"];
      } else {
        return "";
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$content-theme-color-white: #ffffff !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/node_common.scss";

::v-deep .el-input-number {
  .el-input-number__decrease:hover {
    color: $content-theme-hover-color;
  }
  .el-input-number__increase:hover {
    color: $content-theme-hover-color;
  }
}

::v-deep .el-checkbox-group {
  .el-checkbox-button.is-focus .el-checkbox-button__inner {
    border-color: $content-theme-hover-color;
  }
  .el-checkbox-button .el-checkbox-button__inner:hover {
    color: $content-theme-hover-color;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner:hover {
    color: $content-theme-color-white;
  }
}

::v-deep .el-button--default {
  :hover {
    color: $content-theme-color;
  }
}

::v-deep .el-checkbox {
  :hover {
    border-color: $content-theme-color;
  }
  .el-checkbox__input .el-checkbox__inner {
    border-color: $content-theme-color;
  }
  .el-checkbox__input + .el-checkbox__label {
    color: $content-theme-color;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }
}

::v-deep .el-date-picker {
  :hover {
    color: $content-theme-hover-color;
  }
  .el-date-picker__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }
  .el-date-picker__label {
    color: $content-theme-color;
  }
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}

.editContentItemModal {
  padding: 0;
  .el-dialog__header {
    padding: 0;
  }
  .el-dialog__body {
    padding: 0;
    overflow: hidden;
  }

  .el-dialog__footer {
    padding: 0;
  }
}

.audio-player {
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  background-color: white;
  color: #454545;

  ::v-deep .audio-bar-contents {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.7rem;
    padding-left: 5px;
    padding-right: 5px;

    .control-group {
      display: flex;
      flex-direction: row;
      min-width: 10px;
      align-items: center;
    }

    .audio-bar-content {
      padding-left: 0;
      padding-right: 3px;
    }

    .audio-control {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .audio-progress {
      padding-left: 5px;
      padding-right: 10px;
      flex: 1;

      &.disabled {
        ::v-deep .el-slider__button {
          background-color: #a0a8b5 !important;
          border-color: #a0a8b5 !important;
        }

        ::v-deep .el-slider__runway {
          height: 1px;
          background-color: #454545;

          ::v-deep .el-slider__bar {
            height: 1px;
            background-color: #4db3f6;
          }

          ::v-deep .el-slider__button {
            width: 3px;
            height: 3px;
            border-radius: 2px;
          }
        }
      }

      ::v-deep .el-slider__runway {
        height: 3px;
        background-color: #454545;
        ::v-deep .el-slider__bar {
          height: 3px;
          background-color: #4db3f6;
        }

        ::v-deep.el-slider__button-wrapper {
          height: 34px;
          width: 12px;

          ::v-deep .el-tooltip {
            vertical-align: middle;
          }
        }
        ::v-deep .el-slider__button {
          width: 10px;
          height: 10px;
          border-radius: 8px;
          border-width: 1px;
          border-color: #454545;
        }
      }
    }
  }
}
</style>
